<template>
    <default-layout class="accRecsPage">
        <b-loading :active="loading"></b-loading>
        <EmptyState v-if="!loading && !count" />
        <template v-if="!loading && count">
            <header class="pageHeader">
                <h1 class="pageTitle">
                    <i class="fas fa-play"></i>Recordings ({{ filteredCount }})
                </h1>
            </header>
            <b-modal class="dateModal" :active.sync="dateModalActive">
                <div class="modal-card">
                    <div class="modal-card-body">
                        <b-datepicker
                            inline
                            range
                            @input="handleDatePickerInput"
                        ></b-datepicker>
                    </div>
                </div>
            </b-modal>
            <nav class="filters">
                <div class="searchBox">
                    <b-field>
                        <b-input
                            class="searchInput"
                            placeholder="Search UIDs, tags, sites, page titles, URLs, devices, IPs, locations, errors"
                            type="text"
                            icon-pack="fas"
                            icon="search"
                            icon-right-clickable
                            v-model.trim="q"
                            :icon-right="q ? 'close-circle' : ''"
                            @icon-right-click="clearSearch"
                            @keyup.enter.native="runSearch"
                        >
                        </b-input>
                        <div class="control">
                            <b-button
                                type="is-primary"
                                class="searchBtn"
                                icon-right="search"
                                @click="runSearch"
                            />
                        </div>
                    </b-field>
                </div>
                <b-dropdown aria-role="list">
                    <button
                        class="button is-primary"
                        type="button"
                        slot="trigger"
                    >
                        <template>
                            <b-icon icon="calendar-alt"></b-icon>
                            <span>Date</span>
                        </template>
                        <b-icon icon="caret-down"></b-icon>
                    </button>
                    <b-dropdown-item
                        v-for="(value, name, index) in filterOpts.date"
                        aria-role="listitem"
                        :key="index"
                        :value="name"
                        @click="handleDateFilterClick(name)"
                    >
                        <div class="media">
                            <div class="media-content">
                                <h3>{{ value }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                    aria-role="list"
                    scrollable
                    :value="filters.site"
                    @change="handleFilterChange('site', $event)"
                >
                    <button
                        class="button is-primary"
                        type="button"
                        slot="trigger"
                    >
                        <template>
                            <span>Sites</span>
                        </template>
                        <b-icon icon="caret-down"></b-icon>
                    </button>
                    <b-dropdown-item
                        v-for="(value, name, index) in filterOpts.site"
                        :key="index"
                        :value="name"
                        aria-role="listitem"
                    >
                        <div class="media">
                            <div class="media-content">
                                <h3>{{ value }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                    aria-role="list"
                    scrollable
                    :value="filters.browser"
                    @change="handleFilterChange('browser', $event)"
                >
                    <button
                        class="button is-primary"
                        type="button"
                        slot="trigger"
                    >
                        <template>
                            <span>Browsers</span>
                        </template>
                        <b-icon icon="caret-down"></b-icon>
                    </button>
                    <b-dropdown-item
                        v-for="(value, name, index) in filterOpts.browser"
                        :key="index"
                        :value="name"
                        aria-role="listitem"
                    >
                        <div class="media">
                            <div class="media-content">
                                <h3>{{ value }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                    aria-role="list"
                    scrollable
                    :value="filters.resolution"
                    @change="handleFilterChange('resolution', $event)"
                >
                    <button
                        class="button is-primary"
                        type="button"
                        slot="trigger"
                    >
                        <template>
                            <span>Resolutions</span>
                        </template>
                        <b-icon icon="caret-down"></b-icon>
                    </button>
                    <b-dropdown-item
                        v-for="(value, name, index) in filterOpts.resolution"
                        :key="index"
                        :value="name"
                        aria-role="listitem"
                    >
                        <div class="media">
                            <div class="media-content">
                                <h3>{{ value }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </nav>
            <div class="activeFilters">
                <b-tag
                    attached
                    closable
                    aria-close-label="Remove filter"
                    v-for="(key, index) in activeFilters"
                    :key="index"
                    @close="removeFilter(key)"
                >
                    {{
                        filterOpts[key]
                            ? filterOpts[key][filters[key]] || filters[key]
                            : filters[key]
                    }}
                </b-tag>
                <a
                    href="#"
                    v-if="activeFilters.length"
                    type="is-text"
                    size="small"
                    @click.prevent="removeAllFilters"
                >
                    Clear all
                </a>
            </div>
            <b-table
                :data="filteredData"
                :default-sort="sort"
                :default-sort-direction="order"
                :per-page="perPage"
                :loading="searching"
                :current-page="currentPage"
                paginated
                striped
                hoverable
                pagination-position="both"
                sort-icon="chevron-up"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                @sort="handleTableSortChange"
                @page-change="handlePageChange"
            >
                <template #top-left>
                    <p class="has-text-dark" v-show="pageCount">
                        Page {{ currentPage }} of {{ pageCount }}
                    </p>
                </template>
                <b-table-column v-slot="props">
                    <a
                        href="#"
                        class="playBtn"
                        @click.prevent="playRec(props.row)"
                    >
                        <i class="fas fa-play-circle fa-fw"></i>
                    </a>
                </b-table-column>
                <b-table-column
                    field="uid"
                    label="User (UID)"
                    sortable
                    v-slot="props"
                >
                    <span style="word-break: break-word;">
                        {{ props.row.uid }}
                    </span>
                </b-table-column>
                <b-table-column
                    field="host"
                    label="Site"
                    sortable
                    v-slot="props"
                >
                    {{ props.row.host }}
                </b-table-column>
                <b-table-column
                    field="startPage.path"
                    label="Start page"
                    cell-class="isUrl"
                    sortable
                    v-slot="props"
                >
                    {{ props.row.startPage.path }}
                </b-table-column>
                <b-table-column
                    field="endPage.path"
                    label="End page"
                    cell-class="isUrl"
                    sortable
                    v-slot="props"
                >
                    {{ props.row.endPage.path }}
                </b-table-column>
                <b-table-column
                    field="durationMs"
                    label="Duration"
                    sortable
                    v-slot="props"
                >
                    <b-tag type="is-dark">{{ props.row.duration }}</b-tag>
                </b-table-column>
                <b-table-column
                    field="pageCount"
                    label="Pages"
                    sortable
                    v-slot="props"
                >
                    <b-tag type="is-dark">{{ props.row.pageCount }}</b-tag>
                </b-table-column>
                <b-table-column
                    field="errorCount"
                    label="Errors"
                    sortable
                    v-slot="props"
                >
                    <b-tag type="is-dark">{{ props.row.errorCount }}</b-tag>
                </b-table-column>
                <b-table-column
                    field="device.browser.name"
                    label="Device"
                    sortable
                    v-slot="props"
                >
                    <b-taglist attached>
                        <b-tag type="is-dark">
                            {{ props.row.device.browser.name }}
                        </b-tag>
                        <b-tag type="is-light">
                            {{ props.row.device.os.name }}
                        </b-tag>
                    </b-taglist>
                </b-table-column>
                <b-table-column
                    field="placeShort"
                    label="Location"
                    sortable
                    v-slot="props"
                >
                    <span class="location" :title="props.row.placeLong">
                        {{ props.row.placeShort }}
                    </span>
                </b-table-column>
                <b-table-column label="IP Address" v-slot="props">
                    <span class="scoop-block">{{ props.row.ip }}</span>
                </b-table-column>
                <b-table-column
                    field="startTs"
                    label="Date"
                    sortable
                    v-slot="props"
                >
                    <div>{{ props.row.startDt }}</div>
                    <span class="liveBadge" v-if="props.row.live">
                        Live
                    </span>
                </b-table-column>
                <template slot="footer" v-if="!filteredCount">
                    No results.
                </template>
            </b-table>
        </template>
    </default-layout>
</template>

<script>
import EmptyState from './EmptyState'

const daysAgo = {
    today: 0,
    yesterday: 1,
    week: 7,
    month: 30,
    year: 365,
}

const sortMap = {
    uid: 'uid',
    'startPage.url': 'startPage',
    'endPage.url': 'endPage',
    durationMs: 'duration',
    pageCount: 'pages',
    errorCount: 'errors',
    'device.browser.name': 'browser',
    placeShort: 'location',
    startTs: 'start',
    endTs: 'end',
}

const prettySortMap = {}
for (let k in sortMap) {
    prettySortMap[sortMap[k]] = k
}

export default {
    name: 'Recs',
    components: {
        EmptyState,
    },
    data() {
        return {
            loading: false,
            searching: false,
            dateModalActive: false,
            dontRunFilters: false,
            filteredData: [],
            currentPage: 1,
            perPage: 10,
            sort: 'startTs',
            order: 'desc',
            q: '',
            filters: {
                q: null,
                date: null,
                site: null,
                browser: null,
                resolution: null,
            },
            filterOpts: {
                site: {},
                browser: {},
                resolution: {},
                date: {
                    today: 'Today',
                    yesterday: 'Yesterday',
                    week: 'Last 7 days',
                    month: 'Last 30 days',
                    year: 'Last 12 months',
                    custom: 'Custom',
                },
            },
        }
    },
    computed: {
        data() {
            return this.$store.getters['account/recs']
        },
        count() {
            return this.data.length
        },
        filteredCount() {
            return this.filteredData.length
        },
        pageCount() {
            return Math.ceil(this.filteredCount / this.perPage)
        },
        activeFilters() {
            const result = []
            for (let key in this.filters) {
                this.filters[key] && result.push(key)
            }
            return result
        },
    },
    watch: {
        $route() {
            !this.dontRunFilters && this.runFilters()
            this.dontRunFilters = false
        },
    },
    created() {
        this.fetchData()
    },
    methods: {
        async fetchData() {
            this.loading = true
            const { success, message } = await this.$store.dispatch(
                'account/loadRecs'
            )
            if (success) {
                this.buildFilterOpts()
                this.runFilters()
            } else {
                this.$toast.error(message)
            }
            this.loading = false
        },
        playRec({ id }) {
            this.$router.push({
                name: 'account.recs.show',
                params: { id },
            })
        },
        validateDateQueryParam(param) {
            if (typeof param !== 'string') {
                return false
            }
            if (this.filterOpts.date[param]) {
                return true
            }
            const [start, end] = param.split('-')
            return isNaN(Date.parse(start)) || isNaN(Date.parse(end))
                ? false
                : true
        },
        runFilters() {
            const query = this.$route.query
            this.currentPage = parseInt(query.page) || 1
            this.sort = prettySortMap[query.sort || 'start']
            this.order = query.order || 'desc'
            this.q = typeof query.q === 'string' ? query.q.trim() : ''
            this.filters = {
                q: this.q,
                site: query.site || null,
                browser: query.browser || null,
                resolution: query.resolution || null,
                date: this.validateDateQueryParam(query.date)
                    ? query.date
                    : null,
            }
            let data = [...this.data]
            for (let k in this.filters) {
                if (!data.length) {
                    break
                }
                if (this.filters[k]) {
                    data = this.filterBy(data, k)
                }
            }
            this.filteredData = data
        },
        runSearch() {
            const { q } = this
            if (q && q !== this.filters.q) {
                this.$router.push({
                    name: 'account.recs',
                    query: {
                        q,
                    },
                })
            }
        },
        clearSearch() {
            if (this.$route.query.q) {
                this.$router.push({
                    name: 'account.recs',
                })
            } else {
                this.q = ''
            }
        },
        removeFilter(key) {
            const newQuery = {
                ...this.$route.query,
            }
            delete newQuery[key]
            this.$router.push({
                name: 'account.recs',
                query: newQuery,
            })
        },
        removeAllFilters() {
            this.$router.push({
                name: 'account.recs',
            })
        },
        handleDateFilterClick(value) {
            if (value === 'custom') {
                this.dateModalActive = true
            } else {
                value !== this.filters.date &&
                    this.handleFilterChange('date', value)
            }
        },
        handleDatePickerInput(value) {
            this.dateModalActive = false
            const [start, end] = value
            const startMonth = start.getMonth() + 1
            const startDay = start.getDate()
            const startYear = start.getFullYear()
            const endMonth = end.getMonth() + 1
            const endDay = end.getDate()
            const endYear = end.getFullYear()
            const range = `${startMonth}/${startDay}/${startYear} - ${endMonth}/${endDay}/${endYear}`
            if (range !== this.filters.date) {
                this.handleFilterChange('date', range)
            }
        },
        handleFilterChange(key, value) {
            this.filters[key] = value
            const newQuery = {
                ...this.$route.query,
            }
            delete newQuery.page
            if (value) {
                newQuery[key] = value
            } else {
                delete newQuery[key]
            }
            this.$router.push({
                name: 'account.recs',
                query: newQuery,
            })
        },
        handlePageChange(page) {
            this.dontRunFilters = true
            this.currentPage = page
            const newQuery = {
                ...this.$route.query,
            }
            if (page > 1) {
                newQuery.page = page
            } else {
                delete newQuery.page
            }
            this.$router.push({
                name: 'account.recs',
                query: newQuery,
            })
        },
        handleTableSortChange(sort, order) {
            if (sort === this.sort && order === this.order) {
                // Needed to avoid `NavigationDuplicated` exception, as this is
                // triggered internally by Buefy when data is filtered.
                return
            }
            this.dontRunFilters = true
            this.sort = sort
            this.order = order
            this.currentPage = 1
            const newQuery = {
                ...this.$route.query,
                sort: sortMap[sort],
                order,
            }
            delete newQuery.page
            this.$router.push({
                name: 'account.recs',
                query: newQuery,
            })
        },
        buildFilterOpts() {
            const sites = {}
            const browsers = {}
            const resolutions = {}
            this.data.forEach(rec => {
                sites[rec.host] = true
                browsers[rec.device.browser.name] = true
                resolutions[rec.resolution] = {
                    resolution: rec.resolution,
                    width: rec.screen.width,
                }
            })
            const sitesSorted = Object.keys(sites)
            sitesSorted.sort()
            sitesSorted.forEach(item => (this.filterOpts.site[item] = item))
            const browsersSorted = Object.keys(browsers)
            browsersSorted.sort()
            browsersSorted.forEach(
                item => (this.filterOpts.browser[item] = item)
            )
            const resolutionsSorted = Object.values(resolutions)
            resolutionsSorted.sort((a, b) => {
                return a.width - b.width
            })
            resolutionsSorted.forEach(
                item =>
                    (this.filterOpts.resolution[item.resolution] =
                        item.resolution)
            )
        },
        filterBy(data, by) {
            switch (by) {
                case 'q':
                    return this.filterByQuery(data)
                case 'site':
                    return this.filterBySite(data)
                case 'browser':
                    return this.filterByBrowser(data)
                case 'resolution':
                    return this.filterByResolution(data)
                case 'date':
                    return this.filterByDate(data)
                default:
                    return data
            }
        },
        filterBySite(data) {
            return data.filter(
                ({ host }) => host.indexOf(this.filters.site) !== -1
            )
        },
        filterByBrowser(data) {
            return data.filter(
                ({ device }) =>
                    device.browser.name.indexOf(this.filters.browser) !== -1
            )
        },
        filterByResolution(data) {
            return data.filter(
                ({ resolution }) => resolution === this.filters.resolution
            )
        },
        filterByQuery(data) {
            const regex = new RegExp(
                // Escape regex special chars
                this.filters.q.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'),
                'gi'
            )
            return data.filter(rec => {
                const tags = Object.values(rec.tags)
                const pages = rec.pages.map(page => `${page.title} ${page.url}`)
                const errors = rec.errors.map(error => error.message)
                return !!(
                    regex.test(rec.uid) ||
                    regex.test(tags.join(' ')) ||
                    regex.test(rec.placeLong) ||
                    regex.test(rec.ip) ||
                    regex.test(pages.join(' ')) ||
                    regex.test(errors.join(' ')) ||
                    regex.test(rec.device.browser.name) ||
                    regex.test(rec.device.os.name)
                )
            })
        },
        filterByDate(data) {
            const value = this.filters.date
            switch (value) {
                case 'today':
                case 'yesterday': {
                    const startDate = new Date()
                    startDate.setDate(startDate.getDate() - daysAgo[value])
                    startDate.setHours(0, 0, 0, 0)
                    const startTs = startDate.getTime() / 1000
                    const endDate = new Date()
                    endDate.setDate(endDate.getDate() - daysAgo[value])
                    endDate.setHours(23, 59, 59, 999)
                    const endTs = endDate.getTime() / 1000
                    return data.filter(
                        rec => rec.startTs >= startTs && rec.endTs <= endTs
                    )
                }
                case 'week':
                case 'month':
                case 'year': {
                    const date = new Date()
                    date.setDate(date.getDate() - daysAgo[value])
                    const unixTime = date.getTime() / 1000
                    return data.filter(rec => rec.startTs >= unixTime)
                }
                default: {
                    // Custom
                    const range = value.split('-')
                    const startDate = new Date(range[0])
                    startDate.setHours(0, 0, 0, 0)
                    const startTs = startDate.getTime() / 1000
                    const endDate = new Date(range[1])
                    endDate.setHours(23, 59, 59, 999)
                    const endTs = endDate.getTime() / 1000
                    return data.filter(
                        rec => rec.startTs >= startTs && rec.endTs <= endTs
                    )
                }
            }
        },
    },
}
</script>
