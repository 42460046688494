<template>
    <div class="emptyState notification">
        <i class="emptyIcon fas fa-play"></i>
        <h4 class="emptyTitle">No scoops yet</h4>
        <h5 class="emptySubtitle">
            Your session recordings will show up here.
        </h5>
        <b-button
            type="is-primary"
            size="is-medium"
            tag="router-link"
            :to="{ name: 'docs' }"
        >
            Get Started Now
        </b-button>
    </div>
</template>

<script>
export default {
    name: 'EmptyState',
}
</script>
